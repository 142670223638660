import futurefitness from '../../img/futurefitness.png';
import './King.css'

function King() {
    return (
        <>
          <div id="kingWrapper" >
          <h2 >Exclusively at</h2>
          <img id="kingImage" src={futurefitness}></img>
        </div>
        </>
    );
  }
  
  export default King;